import "core-js/stable";
import "regenerator-runtime/runtime";

import gsap, {Power4} from 'gsap';
import isMobile from 'ismobilejs';
window.addEventListener('DOMContentLoaded', () => {
  imageAnimation();
  logoAnimation();
});

const imageAnimation = () => {
  const images = document.querySelectorAll('[class*="images--"] img');
  Object.values(images).map((image, index) => {
    gsap.to(image, .3, {
      opacity: 1,
      delay: .1 * index
    })
  })

  const sceneImages = document.querySelectorAll('.home--scene [class*="images--"] img');

  if (!isMobile().phone) {
    const offsets = [];
    Object.values(sceneImages).map(image => {
      offsets.push(Math.random() * .5);

    });
    document.body.addEventListener('mousemove', (e) => {
      const xPos = (e.clientX / window.innerWidth) - 0.5;
      const yPos = (e.clientY / window.innerHeight) - 0.5;

      Object.values(sceneImages).map((image, index) => {
        const rect = image.getBoundingClientRect();

        gsap.to(image, .8, {
          x: (xPos*10) * offsets[index],
          y: (yPos*10) * offsets[index],
          ease: Power4.easeOut,
        });
      })
    })
  }
}

const logoAnimation = () => {
  const logoFlower = document.querySelector('.logo--flower');

   if (logoFlower) {
     gsap.to(logoFlower, .7, {
       rotate: 0,
       opacity: 1,
       x: 0, y: 0
     })
   }

}
